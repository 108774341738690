.App{
  text-align: center;
  .header-container{
    margin: -10% -9% 5%;
    .ui.block.header{
      padding: 1.785714rem 1rem;
      padding-bottom: 15px;
      .ui.image{
        // margin-top: -0.857143em;
        margin-top: -0.5em;
        width: 8.5em;
        margin-right: 78%;
        margin-left: 4em;
      }
    }
    .ui.header .icon:only-child{
      display: block;
      margin-left: 90%;
      cursor: pointer;
    }
    .list-container{
      position: relative;
      display: inline-block;
      margin-top: 8px;
      right: 27%;
      .sub-heading{
        display: inline;
      }
      a:first-child, a:nth-child(2), a:nth-child(3), a:nth-child(4), a:nth-child(5), a:nth-child(6){
        margin-right: 20px;
      }
      a:first-child{
        margin-left: 4.5rem;
      }
    }
    .profile-icon{
      position: relative;
      top: -18px;
      margin-top: -4px;
      margin-right: 4.5em;
    }
  }
  .container{
    .container{
      margin-right: -90px;
    }
  }
  .input-container, .btn-container, select, textarea, .level-container input {
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  .level-container input{
    padding: 5px;
  }
  .level-container{
    display: inline-grid;
    input:first-child{
      margin: 8px 10px 8px 0px;
    }
  }
  .levels-container{
    .projection-container input{
      padding: 12px 20px;
      margin: 2px 33px;
      width: 40rem;
    }
  }
  .projection-container input{
    padding: 12px 20px;
    width: 40rem;
  }
  .projection-level{
    margin-left: 37px !important;
  }
  .add-level-icon, .minus-level-icon, .add-salary-levels{
    margin-left: 14px;
    cursor: pointer;
  }
  .add-salary-levels{
    margin-left: -11rem;
  }
  textarea{
    padding: 16px;
  }
  .input-container {
      width: 250px;
      padding: 12px 20px;
      margin: 8px 0;
  }
  select{
    padding: 10px 24px;
    background-color: #fff;
    margin: 10px;
    width: 47%;
  }
  label{
      font-size: 18px;
  }
  .btn-container{
      padding: 10px 24px;
      color: #fff;
      background-color: blue;
      margin: 6px;
      cursor: pointer;
  }
  .errorMsg{
    color: red;
  }
  .ui.loader{
    display: inline;
  }
  .card{
    width: 18rem;
    display: inline-block;
  }
  table.table tr th, table.table tr td {
    padding: 8px;
  }
  table.table td a {
    color: #a0a5b1;
    display: inline-block;
    margin: 0 5px;
  }
  table.table td a.view {
    color: #03A9F4;
  }
  table.table td a.edit {
    color: #FFC107;
  }
  table.table td a.delete {
    color: #E34724;
  }
  .search-box {
    position: relative;        
    float: left;
    margin-top: -20px;
  }
  .search-box input {
    border-radius: 9px;
  }

  @import url(https://fonts.googleapis.com/css?family=Roboto:300);

.login-page {
width: 360px;
padding: 8% 0 0;
margin: auto;
}
.form {
position: relative;
z-index: 1;
background: #FFFFFF;
max-width: 360px;
margin: 0 auto 100px;
padding: 45px;
text-align: center;
box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
}
.form input {
font-family: "Roboto", sans-serif;
outline: 0;
background: #f2f2f2;
width: 100%;
border: 0;
margin: 0 0 15px;
padding: 15px;
box-sizing: border-box;
font-size: 14px;
}
.form button {
font-family: "Roboto", sans-serif;
text-transform: uppercase;
outline: 0;
background: #4CAF50;
width: 100%;
border: 0;
padding: 15px;
color: #FFFFFF;
font-size: 14px;
-webkit-transition: all 0.3 ease;
transition: all 0.3 ease;
cursor: pointer;
}
.form button:hover,.form button:active,.form button:focus {
background: #43A047;
}
.form .message {
margin: 15px 0 0;
color: #b3b3b3;
font-size: 12px;
}
.form .message a {
color: #4CAF50;
text-decoration: none;
}
/* .form .register-form {
display: none;
} */
/* .container {
position: relative;
z-index: 1;
max-width: 300px;
margin: 0 auto;
} */
.container:before, .container:after {
content: "";
display: block;
clear: both;
}
.container .info {
margin: 50px auto;
text-align: center;
}
.container .info h1 {
margin: 0 0 15px;
padding: 0;
font-size: 36px;
font-weight: 300;
color: #1a1a1a;
}
.container .info span {
color: #4d4d4d;
font-size: 12px;
}
.container .info span a {
color: #000000;
text-decoration: none;
}
.container .info span .fa {
color: #EF3B3A;
}
body {
background: #76b852; /* fallback for old browsers */
background: -webkit-linear-gradient(right, #76b852, #8DC26F);
background: -moz-linear-gradient(right, #76b852, #8DC26F);
background: -o-linear-gradient(right, #76b852, #8DC26F);
background: linear-gradient(to left, #76b852, #8DC26F);
font-family: "Roboto", sans-serif;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;      
}

.separator {
border-right: 1px solid #dfdfe0; 
}
.icon-btn-save {
padding-top: 0;
padding-bottom: 0;
}
.input-group {
margin-bottom:10px; 
}
.btn-save-label {
position: relative;
left: -12px;
display: inline-block;
padding: 6px 12px;
background: rgba(0,0,0,0.15);
border-radius: 3px 0 0 3px;
}
.loader-container{
display: inline-block;
position: absolute;
top: 22rem;
left: 47%;
z-index: 9999;
}
.dashboard-content{
padding-left: 12rem;
.ui.card>.image>img, .ui.cards>.card>.image>img{
  height: 15vw;
}
}
}
.modal-loader{
position: fixed;
left: 50rem;
top: 21rem;
}
.ui.popup {
a, .logout-btn{
  color: black;
  font-size: medium;
  line-height: 35px;
}
.content{
  width: 150px;
  display: grid;
}
.logout-btn{
  cursor: pointer;
}
}

.ui.modal>.close{
top: 1rem;
right: 3.5rem;
color: black;
}
.ui.modal{
position: relative;
background: none;
}
.ui.large.modal>.header:not(.ui){
font-size: 2em;
span{
  position: relative;
  left: 8px;
}
}
.modal-container{
td{
  font-size: 1.5em;
  padding: 5px;
}
td:first-child{
  font-weight: 600;
}
td:last-child{
  padding-left: 3em;
}
}
.img-logo{
float: left;
}
.checkbox-container{
  .ui.checkbox {
    margin-top: 14px;
    label, .radio label{
      font-size: 2em;
      padding-left: 1.2em;
    }
  }
  .ui.checkbox:nth-of-type(2), .ui.checkbox:nth-of-type(3){
    margin-left: 15px;
  }
}
.ui.checkbox:nth-of-type(2){
  margin-left: 15px;
}
.random-label-section{
  .ui.checkbox:nth-of-type(2){
    margin-left: 0px;
  }
}
.img-size{
  margin-left: -21rem;
  font-weight: 600;
}
.crop-img-size{
  margin-top: 6px;
  margin-bottom: 6px !important;
  font-size: small;
  font-weight: 600;
}

// header css 5/01/2021
.ui.block.header{
  background-color: #f4f4f4;
  border-bottom: 1px solid #dcdcdc;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
}
.App .header-container .list-container{
  right: 0px;
}
.App .header-container .ui.block.header .ui.image{
  width: 154px;
  margin-left: 0px;
  padding-left: 25px;
}
.App .header-container .ui.block.header{
  padding:20px 0px;
}
.App .header-container .list-container a{
  padding: 10px 21px;
}
.App .header-container .list-container .ui.menu{
  width: 12%;
}
.App .header-container .list-container .ui.menu .menu .ui.dropdown{
  font-weight: 700;
  line-height: 1.28571429em;
  text-transform: none;
  font-size: 14px;
  color: #337ab7;
  border: none;
  background-color: rgba(0,0,0,0);
}
.App .header-container .list-container a:hover, .ui.menu:hover{
    background: #f6921f;
    color: #fff;
    text-decoration: none;
    border-radius: 4px;
     
}
.App .header-container .list-container a:first-child{
       margin-left: 0px;  
}
.App .header-container .list-container a:first-child, .App .header-container .list-container a:nth-child(2), .App .header-container .list-container a:nth-child(3), .App .header-container .list-container a:nth-child(4), .App .header-container .list-container a:nth-child(5), .App .header-container .list-container a:nth-child(6){
  margin: 0;
}

h3.ui.header{
  font-size: 14px;
}
.ui.primary.button, .ui.primary.buttons .button{
  background-color: #f6921e;
  line-height: 44px;
    height: 44px;
    text-align: center;
    padding: 0 8px;
    cursor: pointer;
    font-size: 15px;
}

.question-error{
  color: red !important;
  text-align: center;
}

.delete-popup{
  .content{
    font-size: 20px !important;
    text-align: center;
    padding: 21px;
  }
  .actions{
    padding: 14px;
    .button{
      padding: 11px 21px;
    }
    .button:last-child{
      background-color: red;
      line-height: 2px;
      height: 32px;
      font-size: 12px;
    }
  }
}

.reset-button{
  line-height: 44px;
  height: 44px;
  text-align: center;
  padding: 0 8px;
  cursor: pointer;
  font-size: 15px;
}

.required_field:after {
  content:" *";
  color: red;
}

.questions-format{
  line-height: 30px;
  font-size: 19px;
  color: rgba(0,0,0,.87);
}

.filter-dropdown-row{
  left: 3rem;
  .filter-dropdown-column{
    top: -30px;
    left: 5rem;
  }
  .filter-btn{
    top: -35px;
  }
}

.user-filter-dropdown-row{
  left: 3rem;
  .user-filter-dropdown-column{
    top: -30px;
    left: 60%;
    .show-deleted-user{
      margin-top: 10px;
      display: flex;
      .deleted-user-heading{
        p{
          margin-left: 7px;
          font-size: 15px;
        }
      }
    }
  }
  .user-filter-btn{
    top: -35px;
  }
}

.reset-filter{
  bottom: 61px;
  position: relative;
  left: 56rem;
}
.checkbox-toggle{
  width: 9%;
  float: left;
}
.quiz-status {
  float: left;
  padding: 2%;
}

.Tag-Search{
  .search-box{
    margin-top: 5px;
  }
}

.tagBtn{
  margin-top: 3px;
}

.questions-container{
  // margin: auto;                      
  // overflow-x: scroll;
  // max-width: 100%;
  //max-height: 100%;
}
.questions-container{
  .table tr td:first-child + td {
    //width: 10% !important;
    //background: red;
  }
}
